<template>
  <v-app :style="colorVapp">
    <onebox_admin_toolbar
      :quicksearch="true"
      @loaddata="loaddata()"
      @loadfile="loadfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_admin_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <v-divider></v-divider>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular
            indeterminate
            size="64"
            :color="color.theme"
          ></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>

        <!-- hearder -->
        <v-list nav :color="color.BG">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-backup-restore</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span :style="headerPage"
                  >&nbsp;{{ $t("admintoolbar.fileandfolderbackup") }}</span
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card
          class="elevation-0"
          :color="color.BG"
          id="thiscontainersetemailadmin"
        >
          <v-card-text class="pa-0">
            <v-container fluid>
              <v-layout
                row
                wrap
                class="pl-3 pr-1"
                v-if="resolutionScreen >= 500"
              >
                <!-- Sort File -->
                <v-flex lg2 md2 xs4>
                  <v-select
                    prepend-inner-icon="sort"
                    hide-details
                    outlined
                    dense
                    solo
                    v-model="size"
                    :items="listsize"
                    class="pt-2 ma-0 pa-0"
                    :item-color="color.theme"
                    :color="color.theme"
                    @change="clickpagination()"
                  >
                    <template v-slot:item="{ item }">
                      <div class="text-center">
                        <span style="font-size: 17px;">{{ item }}</span>
                      </div>
                    </template>
                    <template v-slot:selection="{ item }">
                      <div class="text-center">
                        <span style="font-size: 17px;">{{ item }}</span>
                      </div>
                    </template>
                  </v-select>
                </v-flex>

                <v-flex lg10 md10 xs8 class="pr-2 mt-2 text-right">
                  <v-chip
                    class="ma-2"
                    :color="color.theme"
                    :text-color="color.chipText"
                  >
                    <v-icon left>folder</v-icon>
                    {{ count_folder }}
                    {{ count_folder > 1 ? $t("folders") : $t("folder") }}
                  </v-chip>
                  <v-chip
                    class="ma-2"
                    :color="color.theme"
                    :text-color="color.chipText"
                  >
                    <v-icon left>mdi-file</v-icon>
                    {{ count_file }}
                    {{ count_file > 1 ? $t("files") : $t("file") }}
                  </v-chip>
                </v-flex>
                <v-flex lg4 offset-lg8 xs12 class="pl-3 pr-0">
                  <v-list nav :color="color.BG" class="py-0">
                    <v-list-item>
                      <v-layout>
                        <v-flex class="text-right" lg12>
                          <v-menu offset-y rounded="lg">
                            <template v-slot:activator="{ attrs, on }">
                              <v-btn
                                small
                                rounded
                                class="white--text"
                                :color="color.theme"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon small>mdi-sort</v-icon>
                                {{ $t("sorting.sort") }}:
                                <div class="font-weight-bold">
                                  {{
                                    $t(
                                      sorting["sort"].find(
                                        (x) => x.id === sort_by
                                      ).text
                                    )
                                  }},
                                  {{
                                    $t(
                                      sorting["direction"].find(
                                        (x) => x.id === direction
                                      ).text
                                    )
                                  }}
                                </div>
                              </v-btn>
                            </template>
                            <v-list dense nav>
                              <v-list-item-group
                                v-model="sort_by"
                                active-class="border"
                                :color="color.theme"
                                mandatory
                              >
                                <template v-for="(item, i) in sorting['sort']">
                                  <v-list-item
                                    :key="`item-${i}`"
                                    :value="item.id"
                                    @click="
                                      sort_by === item.id
                                        ? reloadSortByAndDirection()
                                        : ''
                                    "
                                  >
                                    <template>
                                      <v-list-item-content>
                                        <v-list-item-title>{{
                                          $t(item.text)
                                        }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-list-item>
                                </template>
                              </v-list-item-group>
                              <v-divider class="mt-2" />
                              <v-list-item-group
                                mandatory
                                class="mt-4"
                                v-model="direction"
                                active-class="border"
                                :color="color.theme"
                                @click="
                                  direction === item.id
                                    ? reloadSortByAndDirection()
                                    : ''
                                "
                              >
                                <template
                                  v-for="(item, i) in sorting['direction']"
                                >
                                  <v-list-item
                                    :key="`item-${i}`"
                                    :value="item.id"
                                  >
                                    <template>
                                      <v-list-item-content>
                                        <v-list-item-title>{{
                                          $t(item.text)
                                        }}</v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-list-item>
                                </template>
                              </v-list-item-group>
                            </v-list>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-list-item>
                  </v-list>
                </v-flex>
                <v-flex sm-6 md6 xs6 lg4 v-if="resolutionScreen >= 500">
                  <div
                    v-if="statusmenubackup === false"
                    class="pt-2 hidden-sm-and-down"
                  >
                    <v-btn
                      @mouseover="show_menu = true"
                      @mouseleave="show_menu = false"
                      :color="color.theme"
                      :dark="color.darkTheme"
                      rounded
                      class="ma-1"
                      @click="(statusmenubackup = true), (show_menu = false)"
                    >
                      <v-icon>mdi-menu</v-icon>&nbsp;{{ $t("balloon.menu") }}
                      <!-- {{ $t('myinboxPage.multipledownload')}} -->
                      <v-badge
                        v-if="$t('default') === 'th'"
                        style="z-index:10000;bottom:-45px;left:-30px;"
                        :value="show_menu"
                        :content="$t('balloon.menu')"
                        transition="slide-y-transition"
                      ></v-badge>
                      <v-badge
                        v-else
                        style="z-index:10000;bottom:-45px;left:-30px;"
                        :value="show_menu"
                        :content="$t('balloon.menu')"
                        transition="slide-y-transition"
                      ></v-badge>
                    </v-btn>
                  </div>
                  <div v-else class="pt-2">
                    <!-- <v-btn
                      @mouseover="show_allrestore = true"
                      @mouseleave="show_allrestore = false"
                      fab
                      small
                      color="#007105"
                      class="ma-1"
                      @click="confirmAllRestore()"
                      :disabled="loaddataprogresstable"
                    >
                      <v-icon color="white">restore</v-icon>
                      <v-badge
                        v-if="$t('default') === 'th'"
                        style="z-index:10000;bottom:-45px;left:-25px;"
                        :value="show_allrestore"
                        :content="$t('balloon.allrestore')"
                        transition="slide-y-transition"
                      ></v-badge>

                      <v-badge
                        v-else
                        style="z-index:10000;bottom:-45px;left:-35px;"
                        :value="show_allrestore"
                        :content="$t('balloon.allrestore')"
                        transition="slide-y-transition"
                      ></v-badge>
                    </v-btn> -->
                    <v-btn
                      @mouseover="show_restore = true"
                      @mouseleave="show_restore = false"
                      fab
                      small
                      color="green"
                      class="ma-1"
                      @click="confirmMultiRestore()"
                      :disabled="datarestore.length === 0"
                    >
                      <v-icon color="white">mdi-expand-all</v-icon>
                      <v-badge
                        v-if="$t('default') === 'th'"
                        style="z-index:10000;bottom:-45px;left:-25px;"
                        :value="show_restore"
                        :content="$t('balloon.restore')"
                        transition="slide-y-transition"
                      ></v-badge>

                      <v-badge
                        v-else
                        style="z-index:10000;bottom:-45px;left:-35px;"
                        :value="show_restore"
                        :content="$t('balloon.restore')"
                        transition="slide-y-transition"
                      ></v-badge>
                    </v-btn>
                    <v-btn
                      @mouseover="show_clear = true"
                      @mouseleave="show_clear = false"
                      fab
                      small
                      :color="color.theme"
                      :dark="color.darkTheme"
                      class="ma-1 elevation-3"
                      @click="(statusmenubackup = false), (show_clear = false), (datarestore = [])"
                    >
                      <v-icon>clear</v-icon>
                      <v-badge
                        v-if="$t('default') === 'th'"
                        style="z-index:10000;bottom:-45px;left:-20px;"
                        :value="show_clear"
                        :content="$t('balloon.clear')"
                        transition="slide-y-transition"
                      ></v-badge>
                      <v-badge
                        v-else
                        style="z-index:10000;bottom:-45px;left:-30px;"
                        :value="show_clear"
                        :content="$t('balloon.clear')"
                        transition="slide-y-transition"
                      ></v-badge>
                    </v-btn>
                  </div>
                </v-flex>
                <v-flex sm-6 md6 xs12 lg8 class="text-right ml-n4">
                  <!-- ปุ่มกู้คืนทั้งหมด -->
                  <v-btn
                    @mouseover="show_allrestore = true"
                    @mouseleave="show_allrestore = false"
                    small
                    color="success"
                    class="ma-1 mt-6"
                    @click="confirmAllRestore_V2()"
                    :disabled="loaddataprogresstable"
                  >
                    <span>{{ $t("balloon.allrestore") }}</span>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout row wrap class="mt-n2 mx-0" v-else>
                <v-flex xs3 class="text-left">
                  <v-menu offset-y rounded="lg">
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn
                        x-small
                        rounded
                        :color="color.theme"
                        v-bind="attrs"
                        v-on="on"
                        text
                        style="font-size:16px; letter-spacing: 0px;"
                      >
                        <span>{{ $t("sorting.sort") }} : </span>
                        <span class="font-weight-bold" :color="color.theme">
                          {{
                            $t(
                              sorting["sort"].find((x) => x.id === sort_by).text
                            )
                          }}</span
                        ><v-icon size="20">{{
                          $t(
                            sorting["direction"].find((x) => x.id === direction)
                              .text
                          ) === $t("sorting.ascending")
                            ? "mdi-arrow-up"
                            : "mdi-arrow-down"
                        }}</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense nav :style="roundListSort">
                      <v-list-item-group
                        v-model="sort_by"
                        active-class="border"
                        :color="color.theme"
                        mandatory
                        @click="
                          sort_by === item.id ? reloadSortByAndDirection() : ''
                        "
                      >
                        <template v-for="(item, i) in sorting['sort']">
                          <v-list-item :key="`item-${i}`" :value="item.id">
                            <template>
                              <v-list-item-content>
                                <v-list-item-title
                                  :style="
                                    sort_by === item.id
                                      ? listOnBold
                                      : listNoBold
                                  "
                                  ><v-icon size="17" class="mr-2">{{
                                    item.icon
                                  }}</v-icon
                                  >{{ $t(item.text) }}</v-list-item-title
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                      <v-divider class="mt-2" />
                      <v-list-item-group
                        class="mt-4"
                        v-model="direction"
                        active-class="border"
                        :color="color.theme"
                        mandatory
                      >
                        <template v-for="(item, i) in sorting['direction']">
                          <v-list-item
                            :key="`item-${i}`"
                            :value="item.id"
                            @click="
                              direction === item.id
                                ? reloadSortByAndDirection()
                                : ''
                            "
                          >
                            <template>
                              <v-list-item-content>
                                <v-list-item-title
                                  :style="
                                    direction === item.id
                                      ? listOnBold
                                      : listNoBold
                                  "
                                  >{{ $t(item.text) }}</v-list-item-title
                                >
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </v-flex>
                <v-flex xs9 class="ml-n1 text-right">
                  <v-chip
                    style="height: 32px; border-radius: 32px;"
                    class="px-4 mr-2"
                    color="#1876D1"
                    :text-color="color.chipText"
                  >
                    <v-icon center size="18" class="mr-2">folder</v-icon>
                    <!-- {{ count_folder }} {{ count_folder > 1 ? $t("folders") : $t("folder") }} -->
                    <span style="font-weight: 700; font-size:16px;"
                      >{{ count_folder }}
                      <span v-if="resolutionScreen >= 400">{{
                        count_folder > 1 ? $t("folders") : $t("folder")
                      }}</span></span
                    >
                  </v-chip>
                  <v-chip
                    style="height: 32px; border-radius: 32px;"
                    class="px-4"
                    color="#5AB685"
                    :text-color="color.chipText"
                  >
                    <v-icon center size="18" class="mr-2">mdi-file</v-icon>
                    <!-- {{ count_file }} {{ count_file > 1 ? $t("files") : $t("file") }} -->
                    <span style="font-weight: 700; font-size:16px;"
                      >{{ count_file }}
                      <span v-if="resolutionScreen >= 400">{{
                        count_file > 1 ? $t("files") : $t("file")
                      }}</span></span
                    >
                  </v-chip>
                </v-flex>
                <v-flex xs12 class="text-right">
                  <!-- ปุ่มกู้คืนทั้งหมด -->
                  <!-- <v-btn
                    @mouseover="show_allrestore = true"
                    @mouseleave="show_allrestore = false"
                    small
                    color="success"
                    class="ma-1 mt-6"
                    @click="confirmAllRestore()"
                    :disabled="loaddataprogresstable"
                  >
                    <span>{{ $t("balloon.allrestore") }}</span>
                  </v-btn> -->
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-center fill-height>
                <v-flex
                  lg12
                  class="ma-2 ml-4 mr-4"
                  v-if="loaddataprogress === true"
                >
                  <div class="text-center">
                    <v-progress-circular
                      :size="50"
                      :color="color.theme"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </v-flex>
                <v-flex lg12 class="ma-4" v-else>
                  <div v-if="loaddataprogresstable === true">
                    <v-flex lg12 class="ma-2 ml-4 mr-4">
                      <div class="text-center">
                        <v-progress-circular
                          :size="50"
                          :color="color.theme"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                    </v-flex>
                  </div>
                  <div v-else>
                    <!-- mobile -->
                    <v-card
                      v-if="resolutionScreen < 500"
                      class="mt-n2 mx-4"
                      :style="displayMobile"
                      elevation="0"
                    >
                      <v-list
                        two-line
                        :style="styleDisplayMobile"
                        class="elevation-0"
                        v-if="rootfile.length !== 0"
                      >
                        <v-list-item
                          :style="displayMobileForListItem"
                          v-for="item in rootfile"
                          :key="item.file_id"
                          style="cursor: pointer;"
                        >
                          <v-list-item-action>
                            <v-badge
                              :color="item.remain_days_mobile[1]"
                              overlap
                              :content="item.remain_days_mobile[0]"
                            >
                              <v-icon large :color="item.file_icon[1]">{{
                                item.file_icon[0]
                              }}</v-icon>
                            </v-badge>
                          </v-list-item-action>
                          <v-list-item-content class="ml-n2">
                            <v-list-item-title
                              v-text="item.file_name"
                            ></v-list-item-title>
                            <v-list-item-subtitle>
                              {{ calculatesize(item.file_size) }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              {{ formatdatetime(item.backup_dtm) }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              class="elevation-0"
                              fab
                              small
                              @click="
                                datarestore.push(item), confirmMultiRestore()
                              "
                              icon
                            >
                              <v-icon>mdi-history</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                      <v-card-text
                        class="mt-4 mb-4"
                        v-if="
                          rootfile.length === 0 && loaddataprogress === false
                        "
                      >
                        <v-img
                          :src="require('../assets/img/icon_empty_data.png')"
                          max-width="132"
                          max-height="150"
                          class="mx-auto"
                        />
                        <v-list-item-title
                          :style="
                            'font-size: 16px; font-weight: 700; line-height: 24px; color:' +
                              color.theme +
                              ';'
                          "
                          class="text-center mt-8"
                        >
                          {{
                            $t("backupfileandfolder.nofileandfolderforrestore")
                          }}
                        </v-list-item-title>
                      </v-card-text>
                    </v-card>
                    <!-- desktop -->
                    <div v-else style="overflow-x: hidden;">
                      <v-data-table
                        v-model="datarestore"
                        :headers="headers"
                        :items="rootfile"
                        :no-data-text="
                          $t('backupfileandfolder.nofileandfolderforrestore')
                        "
                        :single-select="false"
                        item-key="file_id"
                        item-selected
                        :show-select="statusmenubackup"
                        class="elevation-0"
                        :items-per-page="size"
                        :hide-default-footer="true"
                        :page.sync="page"
                        @page-count="pageCount = $event"
                      >
                        <template v-slot:[`header.file_type`]="{ header }">
                          <span :style="headerTable">{{ header.text }}</span>
                        </template>
                        <template v-slot:[`header.file_name`]="{ header }">
                          <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'name'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.file_owner_th`]="{ header }">
                          <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'owner'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template
                          v-slot:[`header.last_update_name_th`]="{ header }"
                        >
                        <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'owner'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.backup_dtm`]="{ header }">
                          <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'date'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.file_size`]="{ header }">
                          <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'size'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.detail`]="{ header }">
                          <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                        </template>
                        <template v-slot:[`header.remain_days`]="{ header }">
                          <span
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          >
                          <!-- คลิก Sort File จากหัวข้อ -->
                          <!-- <span
                            class="pointer"
                            @click="
                              (multiSort = !multiSort),
                                (sortby = 'date'),
                                (clickSort = true),
                                sortFile(rootfile)
                            "
                            :style="headerTable"
                            >{{ $t(header.text) }}</span
                          > -->
                        </template>
                        <template v-slot:[`header.file_status`]="{ header }">
                          <span :style="headerTable">{{
                            $t(header.text)
                          }}</span>
                        </template>

                        <template v-slot:item="props">
                          <tr style="cursor: pointer">
                            <td class="text-center" v-show="statusmenubackup">
                              <v-checkbox
                                v-model="datarestore"
                                :value="props.item"
                              />
                            </td>
                            <td width="5%" class="text-center">
                              <v-badge
                                color="orange"
                                overlap
                                icon="link"
                                v-if="
                                  props.item.file_status_sharelink === 'Y' ||
                                    props.item.file_status_sharelink === 'P'
                                "
                              >
                                <v-icon
                                  large
                                  :color="props.item.file_icon[1]"
                                  >{{ props.item.file_icon[0] }}</v-icon
                                >
                              </v-badge>
                              <v-icon
                                v-else
                                large
                                :color="props.item.file_icon[1]"
                                >{{ props.item.file_icon[0] }}</v-icon
                              >
                            </td>
                            <td width="18%" :title="props.item.file_name">
                              <div
                                style="whiteSpace: nowrap;overflow: hidden;textOverflow: ellipsis;width:350px"
                              >
                                {{ props.item.file_name }}
                              </div>
                            </td>
                            <td width="15%" v-if="$t('default') === 'th'">
                              {{ props.item.file_owner_th }}
                            </td>
                            <td width="15%" v-else>
                              {{ props.item.file_owner_eng }}
                            </td>
                            <td width="15%" v-if="$t('default') === 'th'">
                              {{ props.item.last_update_name_th || "-" }}
                            </td>
                            <td width="15%" v-else>
                              {{ props.item.last_update_name_en || "-" }}
                            </td>
                            <td width="17%">
                              {{ formatdatetime(props.item.backup_dtm) }}
                            </td>
                            <td width="10%">
                              {{ calculatesize(props.item.file_size) }}
                            </td>
                            <td width="15%" class="text-center" v-if="$t('default') === 'th'">
                              <v-chip
                              :color="props.item.remain_days >= 5 ? 'success' :  props.item.remain_days <= 4 && props.item.remain_days > 1 ? 'warning' : 'error'"
                              >
                              {{ props.item.remain_days <= 1 ? "1 วัน" : props.item.remain_days_th  }}
                              </v-chip>
                            </td>
                            <td width="15%" class="text-center" v-else>
                              <v-chip
                                :color="props.item.remain_days <= 3 ? 'error' : 'success'"
                              >
                                {{ props.item.remain_days <= 1 ? "1 day" :  props.item.remain_days_eng }}
                              </v-chip>
                            </td>
                            <td width="15%" class="text-center">
                              {{ props.item.detail }}
                              <v-btn
                                class="elevation-0"
                                fab
                                small
                                @click="fn_opendiloagdetail(props.item)"
                              >
                                <v-icon>mdi-information</v-icon>
                              </v-btn>
                            </td>
                            <td
                              width="7%"
                              class="text-center"
                              v-if="props.item.file_status === 'M'"
                            ></td>
                            <td width="10%" v-else class="text-center">
                              <v-btn
                                class="elevation-0"
                                fab
                                small
                                @click="
                                  datarestore.push(props.item),
                                    confirmMultiRestore()
                                "
                                outlined
                              >
                                <v-icon>mdi-backup-restore</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </div>
                    <br />
                    <v-layout row wrap justify-center>
                      <v-flex xs11 lg11 v-if="pageCount > 0">
                        <v-pagination
                          :total-visible="10"
                          v-model="page"
                          :length="pageCount || 0"
                          :color="color.theme"
                          @input="clickpagination()"
                        ></v-pagination>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
            <!-- New V using -->
            <confirmmutirestore
              :show="opendialogconfirmmutirestore"
              :statusrestore="statusrestore"
              @closedialog="closedialogconfirmrestore"
              @allrestore="
                (opendialogconfirmmutirestore = false),(opendialogdetailmultirestore = true)
              "
              @multirestore="
                  (opendialogconfirmmutirestore = false),
                  (opendialogdetailmultirestore = true)
              "
            ></confirmmutirestore>

            <!-- Old V  Not use -->
            <!-- <confirmmutirestore
              :show="opendialogconfirmmutirestore"
              :statusrestore="statusrestore"
              @closedialog="opendialogconfirmmutirestore = false"
              @allrestore="
                selectAllFileandFolder(), (opendialogconfirmmutirestore = false)
              "
              @multirestore="
                multiRestoreFileandFolder(),
                  (opendialogconfirmmutirestore = false),
                  (opendialogsnackbarmultirestore = true)
              "
            ></confirmmutirestore> -->

            
            <!-- Old V Not use -->
            <!-- <dialogsnackbarmultirestore
              :show="opendialogsnackbarmultirestore"
              :statusrestore="statusrestore"
              :countrestore="countrestore"
              :filedata="datarestore"
              :showdatarestore="showdatarestore"
              :countcancel="countcancel"
              :countrestoreerror="countrestoreerror"
              @closedialog="
                (opendialogsnackbarmultirestore = false),
                  getBackupfileandfolder(),
                  (datarestore = [])
              "
              @cancelrestore="
                showdatarestore[countrestore - 1].canceltoken.cancel(),
                  (countcancel = 1)
              "
              @cancelselectall="sourceselectall.cancel(), (countcancel = 1)"
              @detailrestore="
                (opendialogsnackbarmultirestore = false),
                  (opendialogdetailmultirestore = true)
              "
            ></dialogsnackbarmultirestore> -->

            <!-- New V (But not use) -->
            <!-- <dialogsnackbarmultirestore
              :show="opendialogsnackbarmultirestore"
              :filedata="sortedselectmulti"
              @detailrestore="opendialogDetialRestore"
              @closedialog="closeSnackBar"
            >
            </dialogsnackbarmultirestore> -->

            <!-- New V using-->
            <dialogdetailrestore
              :show="opendialogdetailmultirestore"
              :showdatarestore="sortedselectmulti"
              :countError="countrestoreerror"
              @closedialog="(opendialogdetailmultirestore = false), (sortedselectmulti = []), (datarestore = [])"
              @closereloaddialog="closeDialogDetailMulti"
            ></dialogdetailrestore>

            <!-- Old V -->
            <!-- <dialogdetailrestore
              :show="opendialogdetailmultirestore"
              :showdatarestore="showdatarestore"
              @closedialog="
                (opendialogsnackbarmultirestore = true),
                  (opendialogdetailmultirestore = false)
              "
            ></dialogdetailrestore> -->

            
            <dialogdetailbackup
            :show="opendialogdetailbackup"
            @closedialog="opendialogdetailbackup = false"
            :filedata="item_detail"
            >
            </dialogdetailbackup>
          </v-card-text>
        </v-card>
      </v-card>
    </v-content>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import gbfGenerate from "../globalFunctions/generateAuthorize";
import gb_setIconFiles from "@/globalFunctions/setIconFiles";
import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";
const confirmmutirestore = () =>
  import("../components/optional/dialog-confirmmultirestore.vue");
const dialogsnackbarmultirestore = () =>
  import("../components/optional/dialog-snackbarmultirestore.vue");
const dialogdetailrestore = () =>
  import("../components/optional/dialog-detailrestore.vue");
const dialogdetailbackup = ()=> 
  import("@/components/optional/dialog-detail-backup");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  components: {
    onebox_admin_toolbar,
    confirmmutirestore,
    dialogsnackbarmultirestore,
    dialogdetailrestore,
    dialogdetailbackup,
  },
  data: function() {
    return {
      item_detail:[],
      opendialogdetailbackup: false,
      statusmenubackup: false,
      opendialogconfirmmutirestore: false,
      opendialogsnackbarmultirestore: false,
      opendialogdetailmultirestore: false,
      rootfile: [],
      datarestore: [],
      loaddataprogresstable: false,
      loaddataprogress: true,
      size: 20,
      page: 1,
      listsize: [10, 20, 50, 100],
      headers: [
        {
          text: "#",
          align: "left",
          value: "file_type",
          width: "5%",
          sortable: false,
        },
        {
          text: "tablefile.filename",
          align: "left",
          value: "file_name",
          width: "18%",
          sortable: false,
        },
        {
          text: "tablefile.owner",
          align: "left",
          value: "file_owner_th",
          width: "15%",
          sortable: false,
        },
        {
          text: "tablefile.lastupdatename",
          align: "left",
          value: "last_update_name_th",
          width: "15%",
          sortable: false,
        },
        {
          text: "tablefile.trashdtm",
          value: "backup_dtm",
          width: "17%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.filesize",
          value: "file_size",
          width: "10%",
          align: "left",
          sortable: false,
        },
        {
          text: "tablefile.remaindays",
          value: "remain_days",
          width: "15%",
          align: "center",
          sortable: false,
        },
        {
          text: "adminlog.detail",
          value: "detail",
          width: "10%",
          align: "center",
          sortable: false,
        },
        {
          text: "tablefile.restore",
          value: "file_status",
          width: "7%",
          align: "center",
          sortable: false,
        },
      ],
      clickSort: false,
      multiSort: false,
      sortby: "",
      sort_by:
        localStorage.getItem("admin_sort_by") !== null
          ? localStorage.getItem("admin_sort_by")
          : "name",
      direction:
        localStorage.getItem("admin_direction_by") !== null
          ? localStorage.getItem("admin_direction_by")
          : "ASC",
      totaldatabackup: 0,
      sorting: {
        sort: [
          {
            id: "name",
            text: "sorting.name",
            icon: "mdi-file-document",
          },
          {
            id: "backup_dtm",
            text: "sorting.trash_dtm",
            icon: "mdi-calendar-multiselect",
          },
          {
            id: "type",
            text: "sorting.type",
            icon: "mdi-text",
          },
          {
            id: "size_file",
            text: "sorting.size_file",
            icon: "mdi-clipboard",
          },
        ],
        direction: [
          {
            id: "ASC",
            text: "sorting.ascending",
          },
          {
            id: "DESC",
            text: "sorting.descending",
          },
        ],
      },
      parentfolder: "",
      filedata: {},
      show_menu: false,
      show_restore: false,
      show_allrestore: false,
      show_clear: false,
      countrestore: 0,
      count_file: 0,
      count_folder: 0,
      countrestoreerror: 0,
      countcancel: 0,
      statusrestore: "",
      sourceselectall: "",
      sourcemultirestore: "",
      sourcerestore: "",
      showdatarestore: [],
      //New Variables
      countwaitmove: 0,
      statusrestoremovefile: '',
      sortedselectmulti: [],
      statusclear: false,
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.totaldatabackup;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 18px; font-weight: 600; line-height: 30px;"
      );
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    //สีอักษรของ headerpage ของ mobile
    headerPageMobile() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 700; line-height: 24px;"
      );
    },
    // ขนาดของ chip ที่แสดง headerpage
    headerChipPage() {
      return "width: 100%; min-height: 40px; max-height: auto; height: auto !important; border-radius: 40px;";
    },
    //สีพื้นหลังและขอบของ card ที่แสดงรายการ
    displayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;";
      } else {
        return "background: #FFFFFF; border: 1px solid #F2F2F2; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1); border-radius: 10px;";
      }
    },
    // ตัวหนา
    listOnBold() {
      return "font-size: 14px; font-weight: 700; line-height: 24px;";
    },
    // ตัวบาง
    listNoBold() {
      return "font-size: 14px; font-weight: 400; color: #636363; line-height: 24px;";
    },
    // ขอบของ sortby
    roundListSort() {
      return "border-radius: 10px; border: 1px solid #f3f5f7; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);";
    },
    // สีพื้นหลังของ list ที่แสดงรายการไฟล์
    styleDisplayMobile() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646;";
      } else {
        return "background: #FFFFFF;";
      }
    },
    // สีพื้นหลังและเส้นขอบล่างของ list item ของแต่ละอัน
    displayMobileForListItem() {
      if (this.$vuetify.theme.dark) {
        return "background: #464646; border-bottom: 1px solid #F3F5F7;";
      } else {
        return "background: #FFFFFF; border-bottom: 1px solid #F3F5F7;";
      }
    },
  },
  watch: {
    sort_by() {
      localStorage.setItem("admin_sort_by", this.sort_by);
      this.getBackupfileandfolder();
    },
    direction() {
      localStorage.setItem("admin_direction_by", this.direction);
      this.getBackupfileandfolder();
    },

    //sort data restore new watch
    datarestore (newVal, oldVal) {
      if(newVal !== oldVal){
        if(newVal.length > 0){
          let newSortBackupDtm = [ ...newVal
              .filter((item) => item.file_type === "folder")
              .sort(function(a, b) { return a.backup_dtm.localeCompare(b.backup_dtm); }).reverse() ,
              ...newVal
              .filter((item) => item.file_type !== "folder")
              .sort(function(a, b) { return a.backup_dtm.localeCompare(b.backup_dtm); }).reverse() ]  
        this.sortedselectmulti = newSortBackupDtm
        }else{
          console.log("This soted selected ", this.sortedselectmulti)
        }
      }else{
        this.sortedselectmulti = [...newVal]
      }
    }
  },
  methods: { 
    // new method
    opendialogDetialRestore (params) {
      this.showdatarestore = params
      this.opendialogsnackbarmultirestore = false
      this.opendialogdetailmultirestore = true
    },
    clearcount () {
      this.countrestoreerror = 0
      this.countcancel = 0
      this.countwaitmove = 0
    },
    closeSnackBar (type) {
      this.opendialogsnackbarmultirestore = false
      this.getBackupfileandfolder()
      this.statusmenubackup = false
    },
    closeDialogDetailMulti (status) {
      // console.log("Hello Thailand ")
      this.opendialogdetailmultirestore = false
        this.getBackupfileandfolder()
        this.statusmenubackup = false
    },
    closedialogconfirmrestore () {
      this.opendialogconfirmmutirestore = false
      this.datarestore = []
    },
    /////////
     fn_opendiloagdetail(item){
      this.item_detail = item;
      this.opendialogdetailbackup = true;
    },
    reloadSortByAndDirection() {
      this.getBackupfileandfolder();
    },
    sendParentfolder() {
      if (this.$route.params.id === undefined) {
        this.parentfolder = this.account_active["directory_id"];
      } else {
        this.parentfolder = this.$route.params.id;
      }
    },
    clickpagination() {
      this.getBackupfileandfolder();
    },

    // Add new method
    async get_name_owner(listrootfile) {
      console.log("listrootfile", listrootfile);
      let payload = {
        data_id: listrootfile.file_id,
        data_type: listrootfile.file_type === "folder" ? "folder" : "file",
        type_search: "creator",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth);
      console.log("auth.code", auth.code);
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE +
            "/api/v1/get/data/creator_name_backup",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            listrootfile.file_owner_eng = response.data.name.name_eng;
            listrootfile.file_owner_th = response.data.name.name_th;
          }
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    async get_last_update_name(listrootfile) {
      console.log("get last update name ", listrootfile);
      let payload = {
        data_id: listrootfile.file_id,
        data_type: listrootfile.file_type === "folder" ? "folder" : "file",
        type_search: "last_update",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      console.log("auth", auth);
      console.log("auth.code", auth.code);
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_SELECT_FILE +
            "/api/v1/get/data/creator_name_backup",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          console.log("Response name last ", response.data)
          if (response.data.status === "OK") {
            listrootfile.last_update_name_eng = response.data.name.name_eng;
            listrootfile.last_update_name_th = response.data.name.name_th;
          }
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
    },
    // get ข้อมูลไฟล์/โฟลเดอร์ที่สำรองข้อมูลไว้
    async getBackupfileandfolder() {
      this.rootfile = [];
      this.loaddataprogresstable = true;
      let cal_limit = this.size;
      let cal_offset = this.size * (this.page - 1);
      let payload = {
        user_id: this.dataUsername,
        account_id: this.dataAccountId,
        folder_id: "",
        status_file: "T",
        status_folder: "T",
        limit: cal_limit,
        offset: cal_offset,
        sort_by: this.sort_by,
        direction: this.direction,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
            "/api/select_files_and_folder_restore",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then(async (response) => {
          if (response.data.status === "OK") {
            this.totaldatabackup = response.data.count;
            for (let i = 0; i < response.data.data.length; i++) {
              let dataFileAndFolder = {};
              let typefile = response.data.data[i].name.split(".");
              let type = response.data.data[i].data_type;
              dataFileAndFolder["account_reciever"] = "";
              dataFileAndFolder["account_sender"] = this.dataAccountId;
              dataFileAndFolder["account_id"] =
                response.data.data[i].account_id;
              dataFileAndFolder["countnum"] = response.data.data[i].countnum;
              dataFileAndFolder["file_createdtm"] =
                response.data.data[i].cre_dtm;
              dataFileAndFolder["folder_id"] = response.data.data[i].folder_id;
              dataFileAndFolder["file_id"] = response.data.data[i].id;
              dataFileAndFolder["file_lastdtm"] =
                response.data.data[i].last_dtm;
              dataFileAndFolder["file_linkshare"] = response.data.data[i].link;
              dataFileAndFolder["file_name"] = response.data.data[i].name;
              dataFileAndFolder["file_owner_eng"] =
                response.data.data[i].name_eng;
              dataFileAndFolder["file_owner_th"] =
                response.data.data[i].name_th;
              dataFileAndFolder["file_permission"] =
                response.data.data[i].permission_account;
              dataFileAndFolder["permission_department_setting"] =
                response.data.data[i].permission_setting;
              dataFileAndFolder["permission_role_setting"] =
                response.data.data[i].permission_role_setting;
              dataFileAndFolder["priority"] = response.data.data[i].priority;
              dataFileAndFolder["file_size"] = response.data.data[i].size;
              dataFileAndFolder["file_position"] =
                response.data.data[i].full_path === null
                  ? "-"
                  : response.data.data[i].full_path;
              dataFileAndFolder["file_status"] = response.data.data[i].status;
              dataFileAndFolder["file_status_share"] =
                response.data.data[i].status_share;
              dataFileAndFolder["file_status_sharelink"] =
                response.data.data[i].status_share_link;
              dataFileAndFolder["system"] = response.data.data[i].system;
              dataFileAndFolder["type"] = response.data.data[i].data_type;
              dataFileAndFolder["file_icon"] = gb_setIconFiles.fn_seticon(type === "folder" ? type : typefile[typefile.length - 1]);
              // dataFileAndFolder["file_icon"] = this.seticon_New(
              //   type,
              //   typefile[typefile.length - 1]
              // );
              dataFileAndFolder["file_type"] =
                type == "folder" ? "folder" : typefile[typefile.length - 1];
              dataFileAndFolder["full_path"] = response.data.data[i].full_path; //Add 
              dataFileAndFolder["full_path_id"] = response.data.data[i].full_path_id; // Add
              dataFileAndFolder["user_id"] = response.data.data[i].user_id;
              dataFileAndFolder["status_lock_folder"] =
                response.data.data[i]["status_lock_folder"];
              dataFileAndFolder["status_lock_file"] =
                response.data.data[i]["status_lock_file"];
              dataFileAndFolder["date_effect"] =
                response.data.data[i].header_info["date_effect"];
              dataFileAndFolder["doc_id"] =
                response.data.data[i].header_info["doc_id"];
              dataFileAndFolder["tax_version"] = "00";
              dataFileAndFolder["backup_dtm"] =
                response.data.data[i].backup_dtm;
              dataFileAndFolder["permission_account_v2"] =
                response.data.data[i].permission_account_v2;
              dataFileAndFolder["last_update_name_th"] =
                response.data.data[i].last_update_name.name_th;
              dataFileAndFolder["last_update_name_en"] =
                response.data.data[i].last_update_name.name_eng;
                dataFileAndFolder["remain_days_eng"] =
                response.data.data[i].remain_days_eng;
              dataFileAndFolder["remain_days_full_eng"] =
                response.data.data[i].remain_days_full_eng;
              dataFileAndFolder["remain_days_full_th"] =
                response.data.data[i].remain_days_full_th;
              dataFileAndFolder["remain_days_th"] =
                response.data.data[i].remain_days_th;
              dataFileAndFolder["remain_days"] =
                response.data.data[i].remain_days;
              // Mai Ment
              // if (response.data.data[i].remain_days > 3) {
              //   dataFileAndFolder["remain_days"] = [
              //     response.data.data[i].remain_days,
              //     "success",
              //   ];
              // } else if (response.data.data[i].remain_days <= 3 && response.data.data[i].remain_days >1) {
              //   dataFileAndFolder["remain_days"] = [
              //     response.data.data[i].remain_days,
              //     "error",
              //   ];
              // } 

              // Mai Add
              dataFileAndFolder["remain_days_mobile"] =
                [response.data.data[i].remain_days,
                response.data.data[i].remain_days >= 5 ? "success" : 
                response.data.data[i].remain_days <= 4 && 
                response.data.data[i].remain_days > 1 ? "warning" : "error"];
              // else if (response.data.data[i].remain_days <= 1) {
              //   dataFileAndFolder["remain_days"] = [
              //     1,
              //     "error",
              //   ];
                
              // }

              this.rootfile.push(dataFileAndFolder);
            }
            this.count_file = response.data.count_file;
            this.count_folder = response.data.count_folder;
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        });
      this.loaddataprogresstable = false;
      // Add new
      for (let y = 0; y < this.rootfile.length; y++) {
        if (this.rootfile[y].file_owner_th === "" || this.rootfile[y].file_owner_eng === "") {
          await this.get_name_owner(this.rootfile[y]);
        }
              
      }

      for (let y = 0; y < this.rootfile.length; y++) {
        if (this.rootfile[y].last_update_name_th === "" || this.rootfile[y].last_update_name_en === "") {
          await this.get_last_update_name(this.rootfile[y]);
        }
      }
      //////

      this.datarestore = [];
    },
    sortFile(parameter) {
      this.folderSort = [];
      this.fileSort = [];
      this.sortdata = [];
      this.rootfile = [];
      let i, j;
      for (i = 0; i < parameter.length; i++) {
        if (parameter[i].file_type === "folder") {
          this.folderSort.push(parameter[i]);
        } else this.fileSort.push(parameter[i]);
      }
      if (this.sortby === "name") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.file_name.localeCompare(b.file_name);
          });
          this.fileSort.sort(function(a, b) {
            return a.file_name.localeCompare(b.file_name);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.file_name.localeCompare(a.file_name);
          });
          this.fileSort.sort(function(a, b) {
            return b.file_name.localeCompare(a.file_name);
          });
        }
      } else if (this.sortby === "date") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.backup_dtm.localeCompare(b.backup_dtm);
          });
          this.fileSort.sort(function(a, b) {
            return a.backup_dtm.localeCompare(b.backup_dtm);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.backup_dtm.localeCompare(a.backup_dtm);
          });
          this.fileSort.sort(function(a, b) {
            return b.backup_dtm.localeCompare(a.backup_dtm);
          });
        }
      } else if (this.sortby === "size") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return b.file_size - a.file_size;
          });
          this.fileSort.sort(function(a, b) {
            return b.file_size - a.file_size;
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return a.file_size - b.file_size;
          });
          this.fileSort.sort(function(a, b) {
            return a.file_size - b.file_size;
          });
        }
      } else if (this.sortby === "owner") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.file_owner_eng.localeCompare(b.file_owner_eng);
          });
          this.folderSort.sort(function(a, b) {
            return a.file_owner_th.localeCompare(b.file_owner_th);
          });
          this.fileSort.sort(function(a, b) {
            return a.file_owner_eng.localeCompare(b.file_owner_eng);
          });
          this.fileSort.sort(function(a, b) {
            return a.file_owner_th.localeCompare(b.file_owner_th);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.file_owner_eng.localeCompare(a.file_owner_eng);
          });
          this.folderSort.sort(function(a, b) {
            return b.file_owner_th.localeCompare(a.file_owner_th);
          });
          this.fileSort.sort(function(a, b) {
            return b.file_owner_eng.localeCompare(a.file_owner_eng);
          });
          this.fileSort.sort(function(a, b) {
            return b.file_owner_th.localeCompare(a.file_owner_th);
          });
        }
      } else if (this.sortby === "position") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.file_position.localeCompare(b.file_position);
          });
          this.fileSort.sort(function(a, b) {
            return a.file_position.localeCompare(b.file_position);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.file_position.localeCompare(a.file_position);
          });
          this.fileSort.sort(function(a, b) {
            return b.file_position.localeCompare(a.file_position);
          });
        }
      }
      this.sortdata.push(this.folderSort);
      this.sortdata.push(this.fileSort);
      for (i = 0; i < this.sortdata.length; i++) {
        for (j = 0; j < this.sortdata[i].length; j++) {
          this.rootfile.push(this.sortdata[i][j]);
        }
      }
      return this.rootfile;
    },
    // ตั้ง icon file/folder
    seticon_New(type, parameter) {
      let dataicon;
      if (typeof parameter === "string") {
        parameter = parameter.toLowerCase();
      } else {
        parameter = parameter;
      }
      if (type == "folder") {
        dataicon = ["mdi-folder", "#ffc107"];
      } else {
        if (parameter === "xlsx" || parameter === "xls") {
          dataicon = ["mdi-file-excel", "#00733b"];
        } else if (parameter === "pptx" || parameter === "ppt") {
          dataicon = ["mdi-file-powerpoint", "#e03303"];
        } else if (parameter === "docx" || parameter === "doc") {
          dataicon = ["mdi-file-word", "#0263d1"];
        } else if (parameter === "zip") {
          dataicon = ["mdi-zip-box", "blue-grey"];
        } else if (parameter === "pdf") {
          dataicon = ["mdi-file-pdf", "#e5252a"];
        } else if (parameter === "xml") {
          dataicon = ["mdi-file-code", "orange"];
        } else if (parameter === "txt" || parameter === "txt") {
          dataicon = ["mdi-note-text", "blue-grey"];
        } else if (
          parameter === "jpeg" ||
          parameter === "jpg" ||
          parameter === "png" ||
          parameter === "gif"
        ) {
          dataicon = ["mdi-file-image", "#0ac963"];
        } else if (
          parameter === "avi" ||
          parameter === "mpeg" ||
          parameter === "mov" ||
          parameter === "mp4" ||
          parameter === "mkv" ||
          parameter === "wmv" ||
          parameter === "3gp" ||
          parameter === "flv"
        ) {
          dataicon = ["mdi-file-video", "purple"];
        } else if (parameter === "shortcut") {
          dataicon = ["mdi-file-link", "pink"];
        } else {
          dataicon = ["mdi-file", "black"];
        }
      }
      return dataicon;
    },
    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
    calculatesize(_sizefilebyte) {
      let size;
      if (_sizefilebyte >= 1099511992567 && _sizefilebyte <= 1125899906842624) {
        size = (_sizefilebyte / 1099511992567).toFixed(2) + " TB";
      } else if (_sizefilebyte >= 1073741824 && _sizefilebyte < 1099511992567) {
        size = (_sizefilebyte / 1073741824).toFixed(2) + " GB";
      } else if (_sizefilebyte >= 1048576 && _sizefilebyte < 1073741824) {
        size = (_sizefilebyte / 1048576).toFixed(2) + " MB";
      } else if (_sizefilebyte >= 1024 && _sizefilebyte < 1048576) {
        size = (_sizefilebyte / 1024).toFixed(2) + " KB";
      } else if (_sizefilebyte === "-") {
        size = _sizefilebyte;
      } else {
        size = _sizefilebyte + " B";
      }

      return size;
    },
    // เด้ง dialog การกู้คืนหลายไฟล์/โฟลเดอร์
    confirmMultiRestore() {
      if (this.datarestore.length > 0) {
        this.statusrestore = "multi";
        this.opendialogdetailmultirestore = true // then this.opendialogconfirmmutirestore = true;
        this.countcancel = 0;
        this.countrestoreerror = 0;
      } else {
        Toast.fire({
          icon: "warning",
          title: this.$t("backupfileandfolder.pleaseselect"),
        });
      }
    },
    // เด้ง dialog การกู้คืนทั้งหมด
    confirmAllRestore() {
      if (this.rootfile.length > 0) {
        this.statusrestore = "all";
        this.opendialogconfirmmutirestore = true;
        this.countcancel = 0;
        this.countrestoreerror = 0;
      } else {
        Toast.fire({
          icon: "error",
          title: this.$t("backupfileandfolder.nofileandfolderforrestore"),
        });
      }
    },
    confirmAllRestore_V2() {
      if (this.rootfile.length > 0) {
        this.statusrestore = "all";
        // Add new
        this.datarestore = [...this.rootfile]
        this.opendialogdetailmultirestore = true
        /////
        this.countcancel = 0;
        this.countrestoreerror = 0;
      } else {
        Toast.fire({
          icon: "error",
          title: this.$t("backupfileandfolder.nofileandfolderforrestore"),
        });
      }
    },
    // วนยิง api เพื่อให้ได้ไฟล์/โฟลเดอร์ทั้งหมด ** ไม่ได้ใข้งานแล้ว (Old V)
    async selectAllFileandFolder() {
      this.datarestore = [];
      this.statusrestore = "start";
      this.countcancel = 0;
      this.countrestoreerror = 0;
      if (this.rootfile.length > 0) {
        this.opendialogsnackbarmultirestore = true;
        let CancelToken = this.axios.CancelToken;
        this.sourceselectall = CancelToken.source();
        let cal_limit = 50;
        let cal_offset = 0;
        for (let i = 0; this.totaldatabackup / cal_limit > i; i++) {
          cal_offset = cal_limit * i;
          let payload = {
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
            folder_id: "",
            status_file: "T",
            status_folder: "T",
            limit: cal_limit,
            offset: cal_offset,
            sort_by: this.sort_by,
            direction: this.direction,
          };
          let auth = await gbfGenerate.generateToken();
          await this.axios
            .post(
              process.env.VUE_APP_SERVICE_DOWNLOAD_API + 
              "/api/select_files_and_folder_restore",
              payload,
              {
                headers: { Authorization: auth.code },
                cancelToken: this.sourceselectall.token,
              }
            )
            .then((response) => {
              if (response.data.status === "OK") {
                for (let i = 0; i < response.data.data.length; i++) {
                  let dataFileAndFolder = {};
                  let typefile = response.data.data[i].name.split(".");
                  let type = response.data.data[i].data_type;
                  dataFileAndFolder["account_reciever"] = "";
                  dataFileAndFolder["account_sender"] = this.dataAccountId;
                  dataFileAndFolder["account_id"] =
                    response.data.data[i].account_id;
                  dataFileAndFolder["countnum"] =
                    response.data.data[i].countnum;
                  dataFileAndFolder["file_createdtm"] =
                    response.data.data[i].cre_dtm;
                  dataFileAndFolder["folder_id"] =
                    response.data.data[i].folder_id;
                  dataFileAndFolder["file_id"] = response.data.data[i].id;
                  dataFileAndFolder["file_lastdtm"] =
                    response.data.data[i].last_dtm;
                  dataFileAndFolder["file_linkshare"] =
                    response.data.data[i].link;
                  dataFileAndFolder["file_name"] = response.data.data[i].name;
                  dataFileAndFolder["file_owner_eng"] =
                    response.data.data[i].name_eng;
                  dataFileAndFolder["file_owner_th"] =
                    response.data.data[i].name_th;
                  dataFileAndFolder["file_permission"] =
                    response.data.data[i].permission_account;
                  dataFileAndFolder["permission_department_setting"] =
                    response.data.data[i].permission_setting;
                  dataFileAndFolder["permission_role_setting"] =
                    response.data.data[i].permission_role_setting;
                  dataFileAndFolder["priority"] =
                    response.data.data[i].priority;
                  dataFileAndFolder["file_size"] = response.data.data[i].size;
                  dataFileAndFolder["file_position"] =
                    response.data.data[i].full_path === null
                      ? "-"
                      : response.data.data[i].full_path;
                  dataFileAndFolder["file_status"] =
                    response.data.data[i].status;
                  dataFileAndFolder["file_status_share"] =
                    response.data.data[i].status_share;
                  dataFileAndFolder["file_status_sharelink"] =
                    response.data.data[i].status_share_link;
                  dataFileAndFolder["system"] = response.data.data[i].system;
                  dataFileAndFolder["type"] = response.data.data[i].data_type;
                  dataFileAndFolder["file_icon"] = gb_setIconFiles.fn_seticon(type === "folder" ? type : typefile[typefile.length - 1]);
                  // dataFileAndFolder["file_icon"] = this.seticon_New(
                  //   type,
                  //   typefile[typefile.length - 1]
                  // );
                  dataFileAndFolder["file_type"] =
                    type == "folder" ? "folder" : typefile[typefile.length - 1];
                  dataFileAndFolder["user_id"] = response.data.data[i].user_id;
                  dataFileAndFolder["status_lock_folder"] =
                    response.data.data[i]["status_lock_folder"];
                  dataFileAndFolder["status_lock_file"] =
                    response.data.data[i]["status_lock_file"];
                  dataFileAndFolder["date_effect"] =
                    response.data.data[i].header_info["date_effect"];
                  dataFileAndFolder["doc_id"] =
                    response.data.data[i].header_info["doc_id"];
                  dataFileAndFolder["tax_version"] = "00";
                  dataFileAndFolder["backup_dtm"] =
                    response.data.data[i].backup_dtm;
                  dataFileAndFolder["permission_account_v2"] =
                    response.data.data[i].permission_account_v2;
                  dataFileAndFolder["last_update_name_th"] =
                    response.data.data[i].last_update_name.name_th;
                  dataFileAndFolder["last_update_name_en"] =
                    response.data.data[i].last_update_name.name_eng;
                  dataFileAndFolder["remain_days"] =
                    response.data.data[i].remain_days;
                    dataFileAndFolder["remain_days_eng"] =
                    response.data.data[i].remain_days_eng;
                  dataFileAndFolder["remain_days_full_eng"] =
                    response.data.data[i].remain_days_full_eng;
                  dataFileAndFolder["remain_days_full_th"] =
                    response.data.data[i].remain_days_full_th;
                  dataFileAndFolder["remain_days_th"] =
                    response.data.data[i].remain_days_th;


                    
                  this.datarestore.push(dataFileAndFolder);
                }
              } else {
                this.countrestoreerror = 1;
              }
            })
            .catch((error) => {
              if (this.countcancel > 0) {
                this.statusrestore = "cancelselect";
              } else {
                this.countrestoreerror = 1;
              }
            });
          if (this.countrestoreerror > 0) {
            break;
          } else {
            continue;
          }
        }
        if (this.countcancel > 0) {
          this.statusrestore = "cancelselect";
        } else if (this.countrestoreerror > 0) {
          this.opendialogsnackbarmultirestore = false;
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox"),
          });
        } else {
          this.multiRestoreFileandFolder();
        }
      } else {
        Toast.fire({
          icon: "error",
          title: this.$t("backupfileandfolder.nofileandfolderforrestore"),
        });
      }
    },
    // ฟังก์ชันการกู้คืนหลายไฟล์/โฟลเดอร์หรือทั้งหมด **ไม่ได้ใช้งานแล้ว (Old V)
    async multiRestoreFileandFolder() {
      this.statusrestore = "restore";
      this.countrestore = 0;
      this.showdatarestore = [];
      this.countcancel = 0;
      let CancelToken = this.axios.CancelToken;
      this.sourcemultirestore = CancelToken.source();
      for (let i = 0; this.datarestore.length > i; i++) {
        this.countrestore = i + 1;
        // สร้างข้อมูลสำหรับโชว์
        let datarestore = {
          restore_status: {
            status: "",
            message: "",
            value: 0,
          },
          canceltoken: this.sourcemultirestore,
          file_name: this.datarestore[i].file_name,
        };
        this.showdatarestore.push(datarestore);
        let payload = {};
        if (this.datarestore[i].type === "folder") {
          payload = {
            data_id: this.datarestore[i].file_id,
            data_type: "folder",
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
          };
        } else {
          payload = {
            data_id: this.datarestore[i].file_id,
            data_type: "file",
            user_id: this.dataUsername,
            account_id: this.dataAccountId,
          };
        }
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              "/api/restore_file_folder_from_backup",
            payload,
            {
              headers: { Authorization: auth.code },
              cancelToken: this.showdatarestore[i].canceltoken.token,
              onUploadProgress: (e) => {
                if ((e.loaded / e.total) * 100 >= 95) {
                  this.showdatarestore[i].restore_status.value = 95;
                } else {
                  this.showdatarestore[i].restore_status.value = parseInt(
                    (e.loaded / e.total) * 100
                  );
                }
              },
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              this.showdatarestore[i].restore_status.status = "OK";
              this.showdatarestore[i].restore_status.message = "success";
              this.showdatarestore[i].restore_status.value = 100;
            } else {
              this.showdatarestore[i].restore_status.status =
                response.data.status;
              this.showdatarestore[i].restore_status.message =
                response.data.errorMessage;
              this.countrestoreerror = this.countrestoreerror + 1;
            }
          })
          .catch((error) => {
            if (this.countcancel > 0) {
              this.showdatarestore[i].restore_status.status = "ER";
              this.showdatarestore[i].restore_status.message = "Cancel Restore";
              this.statusrestore = "cancelrestore";
            } else {
              this.showdatarestore[i].restore_status.status = "ER";
              this.showdatarestore[i].restore_status.message = error;
              this.countrestoreerror = this.countrestoreerror + 1;
            }
          });
      }
      if (this.countrestoreerror === 0 && this.countcancel === 0) {
        setTimeout(() => {
          this.opendialogsnackbarmultirestore = false;
          this.getBackupfileandfolder();
        }, 2500);
      }
    },
  },
  mounted() {
    this.getBackupfileandfolder();
    this.loaddataprogress = false;
  },
};
</script>
<style>
#thiscontainersetemailadmin {
  height: calc(101vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>
